.App {
  text-align: center;
  background-color: #ffffff;
  min-height: calc(100vh);
}

:root {
  --breakpoint-sm: 600px;
  /* --theme-color-1: #008072; */
  --theme-color-1: #008060;
  /* --theme-color-1: #4DA0FF; */
  --theme-color-2: #e1e1e1;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
h2 {
  text-align: center;
  font-family: lsBook, HelveticaNeue, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 1.75rem;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
a {
  font-weight: 400;
  cursor: pointer;
  font-family: lsBook, HelveticaNeue, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}
.btn-primary {
  min-width: 230px;
  padding: 10px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1em;
  font-weight: 700;
  border-radius: 25px;
  transition: 150ms ease;
  border-width: 2px;
  color: rgb(255, 255, 255);
  background-color: var(--theme-color-1);
  /* background-color: #00B78A; */
  /* background-color: #008060; */
  /* background-color: #4DA0FF; */
  max-width: 320px;
  margin: auto;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  outline: none;
}
.submit {
  height: 55px;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn-primary:hover {
  filter: brightness(90%);
  /* background: #3090FF; */
}
.btn-primary:disabled{
  filter: brightness(80%);
}
input {
  -webkit-appearance: none;
}
.form-container {
  margin: auto;
  max-width: 600px;
  margin-top: 50px;
}
.mobile-menu-item {
  font-weight: bolder;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.jamfree-features {
  background-color: var(--theme-color-2);
  padding-bottom: 20px;
}
.jamfree-features-list {
  max-width: 400px;
  margin: auto;
  padding: 20px;
}
.jamfree-integrations{

}
.jamfree-form {
  margin-top: 50px;
  background-color: var(--theme-color-2);
  min-height: calc(100vh);
  padding-bottom: 50px;
}
.form-paper {
  margin-top: 80px;
  padding-top: 20px;
  padding-bottom: 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.form-submission {
  margin-top: 80px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}
.form-submission-signature {
  background-color: var(--theme-color-1);
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 20px;
}
hr.solid {
  border-top: 2px solid var(--theme-color-2);
  border-radius: 30px;
  max-width: 100%;
}
.divider {
  font-weight: 500;
}
.pricing-title {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  font-weight: 500;
  color: var(--theme-color-1);
}
.pricing-card {
  margin: 10px;
}
.plan-description{
  padding-bottom: 12px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 18px;
}
.plan-button{
  background: #f6f9fc;
  display: block;
  text-align: center;
  text-decoration: none;
  padding:12px;
  transition: "background .15s ease";
}
.plan-pricing{
  margin-bottom: 10px;
  text-align: center;
  display: flex;
}
.plan-price{
  font-size: 3em;
  letter-spacing: -.05em;
  vertical-align: middle;

}
.common-UppercaseText{
  font-size: 17px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: .025em;
  text-transform: uppercase;
}
.plan-rate{
  font-size: 19px;
  padding: 12px;
}
.contact-nav-item{
  font-size: 17px;
  color: #8898aa;
  font-weight: 500;
  letter-spacing: .025em;
}
.integration-image{
  max-width: 200px;
  cursor: pointer;
  transition: transform 250ms;

}
.integration-image:hover{
  transform: translateY(-5px);
}
@media only screen and (max-width: 600px) {
  .jamfree-features-list {
    max-width: 300px;
  }
  .form-container {
    margin-bottom: 200px;
  }
  .pricing-title {
    font-weight: 400;
  }
}
.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

